import { asText, Content } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import { Icon } from 'components/@tedui';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import cx from 'classnames';
import PrismicConditionalLink from 'components/PRISMIC/PrismicConditionalLink';

import type { JSX } from 'react';

/**
 * Props for `FeaturedLink`.
 */
export type FeaturedLinkProps = SliceComponentProps<Content.FeaturedLinkSlice>;

/**
 * Component for "FeaturedLink" Slices.
 */
const FeaturedLink = ({ slice }: FeaturedLinkProps): JSX.Element => {
  return (
    <div className={cx(styles['pages-featured-link'], styles.media)}>
      <PrismicConditionalLink
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        field={slice.primary.link}
      >
        <Icon
          iconName="arrow-right-circle"
          className={cx(styles['pages-featured-link__icon'], 'float-left')}
        />
        <div className={cx(styles['media__message'], styles.module)}>
          <h4 className={styles.h7}>{asText(slice.primary.text)}</h4>
          <PrismicRichText field={slice.primary.detail} />
        </div>
      </PrismicConditionalLink>
    </div>
  );
};

export default FeaturedLink;
