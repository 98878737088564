import { PrismicLink, PrismicLinkProps } from '@prismicio/react';
import { isFilled } from '@prismicio/client';

import type { JSX } from 'react';

type PrismicConditionalLinkProps = {
  suffix?: string;
  className?: string;
} & PrismicLinkProps;

const PrismicConditionalLink = ({
  field,
  children,
  onClick,
  suffix = '',
  className
}: PrismicConditionalLinkProps): JSX.Element => {
  if (isFilled.link(field)) {
    const finalUrl = suffix ? `${field.url}${suffix}` : field.url;

    return (
      <PrismicLink
        field={field}
        onClick={onClick}
        href={decodeURI(finalUrl)}
        className={className}
      >
        {children}
      </PrismicLink>
    );
  }
  return <>{children}</>;
};

export default PrismicConditionalLink;
